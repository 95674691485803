.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  background-color: var(--main-color) !important;
  box-shadow: 24;
  padding: 20px;
  border-radius: 7px;
  min-width: 25vw;
}