#message-container {
    padding: 1rem;
    position: fixed;
    bottom: 10px;
    width: 100%;
    left: 0px;
}

#message-container #message {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    background: var(--kind-gray);
    border-radius: 10px;
}

#message .circle {
    width: 15px;
    height: 15px;
    border-radius: 100%;
}

#message .circle-container {
    display: grid;
    place-items: center;
}

#message .circle.red {
    background: red;
}

#message .circle.green {
    background: green;
}

#message .exit-icon:hover {
    cursor: pointer;
}

#message #circle-container {
    font-weight: 100;
    white-space: nowrap;
}