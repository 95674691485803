:root {
    --main-color: #292929;
    --second-color: red;
    --third-color: white;
    --kind-gray: #333333;
    
}

body {
    background-color: var(--main-color);
    color: var(--third-color);
}

input {
    background-color: var(--main-color);
    outline: none;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.3);
    border: none;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 7px;
    color: var(--third-color);
    font-weight: 100;
}

.touchable:hover {
    cursor: pointer !important;
}

.max-width {
    width: 100%;
}

.justify-space-around {
    justify-content: space-around;
}

.display-inline {
    display: inline-flex;
    place-items: center;
}

.my-container {
    padding: 3rem;
    border-radius: 12px;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.3);
}

.btn-primary {
    background-color: var(--second-color);
    border: none;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active
 {
    background-color: var(--second-color);
    border: none;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.3);
    box-shadow: none;
}

.btn-primary:focus-visible {
    outline: none;
}

.gray-bkg {
    background-color: var(--kind-gray);
}

.middle-screen {
    display: grid;
    place-content: center;
}

.full-screen {
    height: 100vh;
}