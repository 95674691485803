#dashboard #title {
    color: red;
    box-shadow: 0px 2px 4px rgba(0,0,0, 0.5);
}

#dashboard #title h1{
    font-weight: 800;
    font-size: 70px;
    
}

#dashboard .my-column {
    border-radius: 15px;
    padding: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    position: relative;
    overflow: hidden;
    min-height: 600px;
    max-height: 50vh;
}

#dashboard .my-column#left {
    padding-bottom: 70px;
}

#dashboard .driver-header,
#dashboard .justify-content-between {
    justify-content: space-between;
    display: flex;
}

#dashboard .delete-all {
    color: red;
}

#dashboard .delete-all:hover {
    cursor: pointer;
    text-decoration: underline;
}

#dashboard #driver-list {
    max-height: 80%;
    overflow: scroll;
}

#dashboard #driver-list::-webkit-scrollbar {
    display: none;
}

#dashboard .driver-item {
    justify-content: space-between;
    place-items: center;
    display: flex;
    background-color: var(--main-color);
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 15px;
    margin-top: 7px;
    margin-bottom: 7px;
}

#dashboard .driver-item *:hover{
    cursor: default;
}

#dashboard .driver-item div h5 {
    font-weight: 400;
}

#dashboard .driver-item div p {
    font-weight: 100;
}

#dashboard #add-driver-container {
    background-color: #444444;
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
}

#dashboard #add-driver-container p:hover {
    cursor: pointer;
}

#dashboard .bottom-right {
    position: absolute;
    bottom: 1rem;
    right: 2rem;
}

#dashboard .item-selected {
    border: 1px solid white;
}

#dashboard input.error {
    border: 1px solid red;
}

#dashboard .custom_trial_container {
    display: flex;
}

#dashboard .custom_trial {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--main-color);
    width: fit-content;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 18px;
    padding-right: 15px;
    border-radius: 10px;
    margin: 10px;
}

#dashboard .custom_trial p {
    margin-right: 10px;
    margin-bottom: 0px;
}

#dashboard .custom_trial svg{
    margin-right: 3px;
}

#dashboard .custom_trial svg:hover{
    cursor: pointer;
}


#right_panel {
    color: white;
    padding: 20px;
    background-color: var(--kind-gray);
}

#right_panel input {
    width: -webkit-fill-available;
    padding-bottom: 30px;
}

#right .three_cols_layout {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.scrollable {
    overflow: scroll !important;
}

.MuiPaper-root {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: var(--kind-gray) !important;
}